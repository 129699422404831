import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { makeStyles } from '@mui/styles';
import { StyledTextField } from '../../assets/GlobalStyles/TextField';
import { Autocomplete } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { selectNodeData } from '../../redux/actions/nodeActions';
import { setSearchStrainMode } from '../../redux/actions/renderActions';
import { fetchSelectedStrain, setStrainSearchStatus, fetchStrainsList, setStrainsList } from '../../redux/actions/treeDataActions';
import { emptyObject } from '../../functions/functions';
import { treeOrderDictSelector } from '../../redux/selectors/treeDataSelector';

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        textAlign: 'left',
        padding: '0px 0px',
        width: '100%'
    },
    formControl: {
        minWidth: 120,
        margin: '8px 0px'
    },
    field: {
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },
});

const emptyList = [];

const useStyles = makeStyles(styles);

const TreeStrainSearch = props => {

    const [error, setError] = useState(false);
    const [errorTxt, setErrorTxt] = useState();
    const [shouldBeEmpty, setShouldBeEmpty] = useState(true);
    const classes = useStyles();

    // state = {
    //     error: false,
    //     errorTxt: null,
    //     shouldBeEmpty: true
    // }
    // value = null;
    // currentPattern = '';

    const txt = useRef();
    const currentPattern = useRef('');

    // _element = React.createRef();
    //_isMounted = false;

    const lastChosenId = useRef();
    // const lastSelectedStrain = useRef();
    
  
    const { lineage, loading, strainSearchStatus, fetchStrainsList, searchId, setStrainsList, searchedStrainList,
        selectNodeData,
        colorBy,
        setSearchStrainMode,
        fetchSelectedStrain, treeOrderDict, setStrainSearchStatus, 
        zoomNodeId, label } = props;

    useEffect(() => {
        if (loading) return;
        if (strainSearchStatus !== 'loading' && strainSearchStatus !== 'none') {
            checkStrain(lastChosenId.current);
        }
    })


    const fetchStrains = (lineage, strainsTxt) => {

        //console.log(`fetchStrains: strainsTxt = ${strainsTxt}`);
        if (strainsTxt.length < 3) {
            clearStrainsList();
            setShouldBeEmpty(true);
        }

        if (!emptyObject(strainsTxt) && (strainsTxt.length === 3 || (strainsTxt.length > 3 && (!strainsTxt.match(currentPattern.current) || currentPattern.current === '')))) {
            currentPattern.current = strainsTxt
            fetchStrainsList({ lineage, strainsTxt, searchId })
            setShouldBeEmpty(false);
        }
    };

    const clearStrainsList = () => {
        setStrainsList({ strainsList: emptyList })
    }

    const handleStrainInputChange = (event, value, reason) => {
        value = value.toUpperCase();
        console.log(`[handleStrainInputChange]: ${value}, reason = ${reason}, value = ${value}, currentPattern = ${currentPattern.current}, ${value.match(currentPattern.current)}`);
        txt.current = value;

        if (!value.match(currentPattern.current)) currentPattern.current = '';
        if (reason === 'input' || reason === 'clear') {
            if (reason === 'clear' || (value || '').length === 0) {
                clearStrainsList();
                lastChosenId.current = null;
            }
            console.log('will fetchStrains', lineage, value)
            fetchStrains(lineage, value)
        }
    }


    const handleSelectedStrain = (_, selectedStrain) => {
        // debugger;
        console.log(`[handleSelectedStrain]`, selectedStrain);
        if (emptyObject(selectedStrain) || loading) return;

        const id = /* selectedStrain.secondary ? selectedStrain.p :*/ selectedStrain.id;
        ///console.log(`[handleSelectedStrain], id = ${id}, order = ${treeOrderDict[id]}`);
        setSearchStrainMode(true);
        if (!treeOrderDict[id]) {
            fetchSelectedStrain({ lineage, colorBy, strainId: id, showRule: 'showSelectedStrain', searchId, zoomNodeId });
            lastChosenId.current = id;
        }
        else {

            setStrainSearchStatus({ strainSearchStatus: 'found', searchId });
            //console.log(`[handleSelectedStrain]: found, setSearchStrainMode = true ${label}, order = ${treeOrderDict[id]}, id = ${id}`);

            selectNodeData({ nodeId: id });
        }
    }

    const checkStrain = (id) => {
        if (!lastChosenId.current) {
            setError(false);
            setErrorTxt(null);
        }
        if (strainSearchStatus === 'not_found' && lastChosenId.current) {
            setSearchStrainMode(false);
            setError(true);
            setErrorTxt('Strain not in the tree time window');
            lastChosenId.current = null;
        }
        else if (strainSearchStatus === 'not_in_scope' && lastChosenId.current) {
            setSearchStrainMode(false);
            setError(true);
            setErrorTxt('Strain not in the selected branch, zoom out and search again');
        }
        else if (id) {
            setSearchStrainMode(true);
            selectNodeData({ nodeId: id });
        }
    }

    const undefinedToNull = (val) => val === undefined ? null : val;
    const strainsList =  (searchedStrainList || emptyList);
    // console.log(`lastChosenId = ${lastChosenId.current}`);
    const strainOption = undefinedToNull((lastChosenId.current && strainsList.length > 0) ? strainsList.find(v => v.id === lastChosenId.current) : null);
    return (
        <div className={classes.root}>
            <Autocomplete
                id="strainSearch"
                className={classes.field}
                options={shouldBeEmpty ? [] : strainsList}
                isOptionEqualToValue={(option, value) => value && option.n === value.n}
                getOptionLabel={option => option ? option.n : ''}
                loading={loading}
                autoSelect={true}
                value={strainOption}
                defaultValue={""}

                onInputChange={handleStrainInputChange}
                onChange={handleSelectedStrain}
                renderInput={(params) => (
                    <StyledTextField {...params}
                        label={label}
                        id="textField"
                        style={{marginTop: '8px'}}
                        fullWidth
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
            />

            {error && (<div style={{ color: 'red' }}>{errorTxt}</div>)}
        </div>
    );

}

TreeStrainSearch.propTypes = {
    searchId: PropTypes.string.isRequired,
    lineage: PropTypes.string,
    strainSearchStatus: PropTypes.string,
    fetchStrains: PropTypes.func,
    selectNodeData: PropTypes.func,
    classes: PropTypes.shape({ root: PropTypes.string, field: PropTypes.string }),
    context: PropTypes.string,
    label: PropTypes.string,
    treeOrderDict: PropTypes.shape({}),
    fetchSelectedStrain: PropTypes.func,
    setSearchStrainMode: PropTypes.func
};

const mapStateToProps = (state, ownProps) => ({
    treeAttrs: state.treeData.treeAttrs,
    treeOrderDict: treeOrderDictSelector(state),
    searchedStrainList: state.treeData.strainsLists[ownProps.searchId],
    strainSearchStatus: (state.treeData.strainSearchStatuses && state.treeData.strainSearchStatuses[ownProps.searchId]),
    cladesStatus: state.cladeData.cladesStatus,
    clades: state.cladeData.clades,
    lineage: state.parameters.lineage,
    colorBy: state.parameters.colorBy,
    modelId: state.parameters.modelId,
    colorBy: state.parameters.colorBy,
    zoomNodeId: state.parameters.zoomNodeId,
    strainsListStatus: state.treeData.strainsListStatuses[ownProps.searchId],
    loading: (state.treeData.strainsListStatuses && state.treeData.strainsListStatuses[ownProps.searchId] === 'loading')
        || state.treeData.strainSearchStatus === 'loading'
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        selectNodeData,
        //setReferenceClade,
        // setReferenceStrain,
        setSearchStrainMode,
        fetchSelectedStrain,
        //fetchVaccineCandidates,
        //fetchTreeOrder,
        setStrainSearchStatus,
        fetchStrainsList,
        setStrainsList
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TreeStrainSearch);
