import { select } from 'd3-selection';
import { line, curveMonotoneX } from 'd3-shape';
import D3Component from '../../D3Component/D3Component';
import { initSequenceChartScales, getInvertedValue, reinitSequenceChartScales } from '../../../functions/scales';
import { drawChartAxes } from './chartAxes';
import { RENDER_STATUS } from '../../../config/consts';
import { titleCase, dateToDays } from '../../../functions/functions';

const missingDataColor = '#dee0e2';

class FrequencyChartD3 extends D3Component {
    constructor(type) {
        super();
       // this.changePosition = (x, y, info) => chart.handleTooltipPositionChange(x, y, info);
        this.type = type;
        // this.componentName = `${type}Chart`;
    }

    type = '';

    margin = { top: 0, bottom: 0, left: 0, right: 0 };

    marginExport = { top: 0, bottom: 0, left: 0, right: 0 };

    padding = { top: 0, bottom: 32, left: 48, right: 0, title: 40 };

    paddingExport = { top: 0, bottom: 32, left: 48, right: 0, title: 0 };

    paddingMultiExport = { top: 10, bottom: 12, left: 18, right: 0, title: 0 };

    componentName = `${this.type}Chart`;

    xScale = () => {
        const { type } = this.props;
        if (type === 'seq') return 'xSequencesScale';
        return 'xCasesScale';
    };

    yScale = () => {
        const { type, subsetId } = this.props;
        if (type === 'seq') return `ySequences${subsetId}Scale`;
        return `yCases${subsetId}Scale`;

        // return ((this.props && this.props.plotType !== 'frequencies') ? 'yMultiplicityScale' : 'yFrequencyScale')
    };

    yVal = (d) => {
        const { type } = this.props;
        // console.log(d);
        return d[`${type}Cnt`];
    };

    x = (d) => {
        return this._getXValue(d.time);
    };

    y = (d) => this._getYValue(this.yVal(d));

    rawYVal = (d) => {
        const { type } = this.props;
        return d[`raw${titleCase(type)}Cnt`];
    };

    // Area generator
    // freqArea = area()
    //     .x(this.x)
    //     .y0(d => { console.log(`std: time = [${d.time} / ${this.x(d)}], y0 = ${this.y(d.x - d.stdX)}, y1 = ${this.y(d.x + d.stdX)}`); return this.y(d.x - d.stdX); })
    //     .y1(d => this.y(d.x + d.stdX))
    //     .curve(curveMonotoneX);

    freqPlot = line()
        .x(this.x)
        .y((d) => this.y(d))
        .curve(curveMonotoneX); //curveMonotoneX);

    translateRawDataPoint = (d) => `translate(${this._getXValue(d.time)}, ${this._getYValue(this.rawYVal(d))})`;

    rawSeqPlot = line()
        .x(this.x)
        .y(this.rawYVal)
        .curve(curveMonotoneX); //curveMonotoneX);

    // Graph specific elements
    prepareGraphElements = () => {
        const { type, title } = this.props;
        const svg = select(this.mountNode).select('g.graph');

        if (title) {
            svg.append('g')
                .attr('id', `${this.componentName}_title`)
                .append('text')
                .attr('transform', `translate(16, ${this.getPadding().title - 16})`)
                .text(title);
        }
        const axesSvg = svg.append('g').attr('id', 'axes');

        //const padding = this.getPadding();

        axesSvg
            .append('rect')
            .attr('id', 'chartAxis_background')
            .attr('pointer-events', 'all')
            .attr('rx', 7)
            .style('fill', '#FFFFFF');

        // const xAxis =
        axesSvg
            .append('g')
            .attr('id', `${this.componentName}TimeAxis`)
            .attr('transform', `translate(${this.getPadding().left}, ${this.getPadding().title})`);

        // const yAxis =
        axesSvg
            .append('g')
            .attr('id', `${this.componentName}YAxis`)
            .attr('transform', `translate(${this.getPadding().left}, 0)`);

        const plot = svg
            .append('g')
            .attr('id', `${this.componentName}Plot`)
            .attr('rx', 7)
            .style('fill', '#FFFFFF')
            .attr('transform', `translate(${this.getPadding().left}, 0)`);

        // plot.append('g')
        //     .attr('id', 'tooltipNode')
        //     .append('circle')
        //     .attr('r', 5)
        //     .style('opacity', 0);
    };

    sizeX = () => this.props.size;
    sizeY = () =>
        this.props.size || this.props.maxVal >= 1000000 ? 'small' : this.props.maxVal >= 10000 ? 'medium' : undefined;

    translateGraphElements = () => {
        const { type, maxVal, predictionBaseline } = this.props;
        const svg = select(this.mountNode).select(`#${this.componentName}Plot`);
        
        const freqUpdate = svg.selectAll(`path.${type}Layers`);
        freqUpdate.attr('d', (d) => this.freqPlot(d.values));

        const rawUpdate = svg.selectAll(`g.raw${type}Layers`);
        rawUpdate.attr('transform', this.translateRawDataPoint);

        const top = this.getPadding().top + this.getPadding().title;
        svg.select(`g.predictionBaseline`)
            .select('path')
            .attr('d', `M${this.x({ time: dateToDays(predictionBaseline) })},${top},V${this.height + top}`);

        const width = this.getWidth();
        const padding = this.getPadding();

        drawChartAxes(
            select(this.mountNode),
            `${this.componentName}`,
            this.xScale(),
            this.yScale(),
            width,
            this.height,
            this.sizeX(),
            this.sizeY(),
            this.getMargin(),
            padding,
            this.props.multiexport,
        );
    };

    removePlots = () => {
        const { type } = this.props;
        const svg = select(this.mountNode).select(`#${this.componentName}Plot`);
        svg.selectAll(`path.${type}Layers`).remove();
        svg.selectAll(`path.raw${type}Layers`).remove();
        svg.selectAll(`g.raw${type}Layers`).remove();
        svg.select('g.predictionBaseline').remove();
        // svg.selectAll('path.predLayers').remove();
        // svg.selectAll('path.freqStackedLayers').remove();
        // svg.selectAll('path.predStackedLayers').remove();
    };

    reinitXYScales = () => {
        reinitSequenceChartScales(this.width, this.height, this.props.type, this.props.subsetId, this.getPadding());
    };

    setComponentState = (component, state) => {
        component.setState(state);
    };

    mousePos = { x: null, y: null };

    invertX = (posX) => getInvertedValue(this.xScale(), posX);

    drawSmoothedData = (areaChart, inputData, prefix) => {
        // console.log('[drawSmoothedData]', inputData);
        const { exportMode } = this.props;
        const plotData = areaChart.selectAll(`path.${prefix}Layers`).data(inputData || [], (d) => d.key);

        const plotDataEnter = plotData
            .enter()
            .append('path')
            .classed(`${prefix}Layers`, true)
            .attr('id', (d) => `${prefix}_${d.key}`)
            .style('fill', 'none')
            .style('stroke', 'black')
            .style('stroke-width', 2)
            .on('mousemove', this.mouseMoveHandler)
            .on('mouseout', this.mouseOutHandler);
        // if (prefix === 'pred') plotDataEnter.attr('stroke-dasharray', ('3, 3'));

        const plotDataUpdate = plotDataEnter.merge(plotData);


        plotDataUpdate
            // .transition()
            // .duration(expor tMode ? 0 : 2000)
            .attr('d', (d) => this.freqPlot(d.values));

        plotData.exit().remove();
    };

    drawRawData = (areaChart, inputData, prefix) => {
        if (!inputData) return;
        const { exportMode } = this.props;
        const plotData = areaChart.selectAll(`g.raw${prefix}Layers`).data(
            inputData[0].values.filter((d) => this.rawYVal(d) > 0),
            (d) => d.time,
        );

        const plotDataEnter = plotData.enter().append('g').classed(`raw${prefix}Layers`, true);
        //.attr('id', d => `raw${prefix}_${d.time}`)

        plotDataEnter
            .append('circle')
            .attr('r', exportMode ? 2 : 5)
            .attr('fill', 'green');

        // if (prefix === 'pred') plotDataEnter.attr('stroke-dasharray', ('3, 3'));

        const plotDataUpdate = plotDataEnter.merge(plotData);

        plotDataUpdate
            //     .transition()
            //     .duration(exportMode ? 0 : 2000)
            .attr('transform', this.translateRawDataPoint);

        plotData.exit().remove();
    };

    drawPredictionBaseline = (areaChart, predictionBaseline) => {
        if (!predictionBaseline) return;

        areaChart
            .append('g')
            .attr('class', 'predictionBaseline')
            .append('path')
            .attr(
                'd',
                `M${this.x({ time: dateToDays(predictionBaseline) })},${
                    this.getPadding().top + this.getPadding().title
                },V${this.height + this.getPadding().top + this.getPadding().title}`,
            )
            .style('stroke-dasharray', '4, 2')
            .style('stroke', 'black')
            .style('stroke-width', 1.5);
    };

    clearChartData = () => {
        select(`#${this.componentName}`).select(`#${this.componentName}Plot`).selectAll('path').remove();
        select(`#${this.componentName}`).select(`#${this.componentName}Plot`).selectAll('g').remove();
    };

    selectChart = () => select(`#${this.componentName}`).select(`#${this.componentName}Plot`);

    renderSequencesD3Component = () => {
        //console.log('[SequencesChartD3.renderStackedD3Component]', data);
        const { type, data, startTime, endTime, predictionBaseline, showPrediction, maxVal, subsetId } = this.props;
        initSequenceChartScales(this.width, this.height, startTime, endTime, maxVal, type, subsetId, this.getPadding()); // endTime);
        const svg = select(this.mountNode).select('g.graph');
        const width = this.getWidth();

        drawChartAxes(
            svg,
            `${this.componentName}`,
            this.xScale(),
            this.yScale(),
            width,
            this.height,
            this.sizeX(),
            this.sizeY(),
            this.getMargin(),
            this.getPadding(),
            this.props.multiexport,
        );
        //}
        if (data) {
            const areaChart = select(`#${this.componentName}`).select(`#${this.componentName}Plot`);
            // if (this.componentName !== 'seqNAChart') {
            this.drawSmoothedData(areaChart, data, type);
            this.drawRawData(areaChart, data, type);
            this.drawPredictionBaseline(areaChart, showPrediction ? predictionBaseline : null);
        }
    };

    renderD3Component = async (viewName, componentId, loading) => {
        const { plotType, chartLayout, setComponentStatus } = this.props;
        // console.log(
        //     `[SequencesChart3D.renderD3Component] plotType = ${plotType}, chartLayout = ${chartLayout}, chartName = ${this.componentName}`,
        // );
        // if (this.componentName === 'seqNAChart') console.log(`[renderD3Component], width = ${this.width}, height = ${this.height}`);
        setComponentStatus(viewName, componentId, RENDER_STATUS.START);
        // console.log('SEQ', viewName, this.props.subsetId, this.props.startTime, this.props.endTime);
        this.renderSequencesD3Component();
        !loading && setComponentStatus(viewName, componentId, RENDER_STATUS.DONE);
    };
}

export default FrequencyChartD3;
