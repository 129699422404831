import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AppBar, Toolbar, IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';
import LogoIcon from '../components/SvgIcons/LogoIcon';
import MenuButton from './MenuButton';
import UserMenu from './userMenu/UserMenu';
import { signOut } from '../redux/actions/userActions';
import { getIsMobile } from '../redux/selectors/statusSelector';
import { setHiddenMenu, setHiddenMenuMobile } from '../redux/actions/renderActions';
import auth from '../functions/auth-helper';
import config from '../config/envConfig';

const styles = {
    root: {
        boxShadow: 'none',
        zIndex: 10,
        position: 'relative',
        // borderBottom: '20px solid #e2e2e2',
        overflowY: 'none',
    },
    logoButton: {
        marginLeft: -12,
        marginRight: 20,
        display: 'flex',
        color: '#EEEEEE',
        '&:hover': {
            color: '#FFFFFF',
            boxShadow: 'none',
            borderColor: 'white',
        },
    },
    menuButton: {
        marginLeft: 20,
        marginRight: 0,
        width: 97,
        hight: 32,
        display: 'flex',
        padding: '2px',
        maxHeight: 32,
        minHeight: 32,
        color: '#000000',
        boxShadow: 'none',
        transition: 'unset !important',
    },
    menuTitle: {
        fontSize: '15px',
    },
    grow: {
        flexGrow: 1,
    },
    mobileMenu: {
        backgroundColor: 'black',
        display: 'flex',
        padding: '10px 5px 10px 0px',
        fontSize: 10
    },
    leftButtons: {
        width: '90%',
        display: 'flex'
    }
};

const Header = (props) => {
    const { history, adminRights, classes, setHiddenMenuMobile, modules, signOut, isAuthenticated, name, permissionTabs, isMobile, setHiddenMenu, hiddenMenu, hiddenMenuMobile } = props;
    
    const location = useLocation();
    const { pathname } = location;
    const isExportExpr = `${config.frontendPrefix}/export`;
    const isExportPath = pathname.startsWith(isExportExpr);

    const handleMenuOpenMobile = () => {
        setHiddenMenuMobile({ hiddenMenuMobile: !hiddenMenuMobile })
    }

    const userAuthenticated = isAuthenticated && auth.isAuthenticated();
    // console.log(`[Header] userAuthenticated = ${userAuthenticated}, ${isAuthenticated}, ${auth.isAuthenticated()}`)
    const RouterLink = React.forwardRef((linkProps, ref) => <Link ref={ref} to={`${config.frontendPrefix}/`} {...linkProps} />);
    return (
        !isExportPath && (
            <AppBar position="static" className={classes.root}>
                <Toolbar>
                    <IconButton className={classes.logoButton} component={RouterLink} color="inherit" aria-label="Menu">
                        <LogoIcon />
                    </IconButton>
                    <div className={classes.grow} />
                    {isMobile ?
                        <>
                            {!userAuthenticated && (
                                <MenuButton label="Sign in" routeName="signin" defaultOption />
                            )}
                            {userAuthenticated && (
                                <UserMenu signOut={signOut} history={history} adminRights={adminRights} name={name} />
                            )}
                        </>
                        :
                        <>
                            {!userAuthenticated && <>
                                <MenuButton label="Tree" routeName="intro" defaultOption />
                                <MenuButton label="Frequencies" routeName="intro-freq" defaultOption />
                                <MenuButton label="Map" routeName="intro-map" defaultOption />
                                <MenuButton label="Data sources" internalLink="#data_sources" noborder={true} variant="text"/>
                                <MenuButton label="Guide" externalLink="https://previr.com/analysis#previr-app" noborder={true} variant="text"/>
                                <MenuButton label="Sign in" routeName="signin" defaultOption noborder={true} variant="text"/>
                            </>
                            }

                            {userAuthenticated && modules &&
                                <>
                                    {modules.strainTree && permissionTabs.strainTree &&
                                        <MenuButton label="Tree" routeName="" defaultOption />
                                    }
                                    {(modules.clades || modules.frequencies) && (permissionTabs.clades || permissionTabs.frequencies) &&
                                        <MenuButton label="Frequencies" routeName="frequencies" />
                                    }
                                    {modules.antigenic && permissionTabs.antigenic &&
                                        <MenuButton label="Antigenic" routeName="antigenic" />
                                    }
                                    {modules.geomap && permissionTabs.map &&
                                        <MenuButton label="Map" routeName="geomap" />
                                    }
                                    <MenuButton label="Data sources" internalLink="#data_sources" noborder={true} variant="text" />
                                    <MenuButton label="Guide" externalLink="https://previr.com/analysis#previr-app" noborder={true} variant="text"/>
                                    <UserMenu signOut={signOut} history={history} adminRights={adminRights} name={name}  noborder={true}/>
                                </>
                            }
                        </>
                    }
                </Toolbar>
                {
                    isMobile &&
                    <div className={classes.mobileMenu}>
                        <div className={classes.leftButtons}>
                            {!userAuthenticated &&
                                <>
                                    <MenuButton label="Tree" routeName="intro" defaultOption />
                                    <MenuButton label="Frequencies" routeName="intro-freq" defaultOption />
                                </>
                            }
                            {userAuthenticated && modules &&
                                <>
                                    {modules.strainTree && permissionTabs.strainTree && <MenuButton label="Tree" routeName="" defaultOption />}
                                    {modules.clades && permissionTabs.frequencies && <MenuButton label="Frequencies" routeName="frequencies" />}
                                    {modules.antigenic && permissionTabs.antigenic && <MenuButton label="Antigenic" routeName="antigenic" />}
                                    {modules.geomap && permissionTabs.map && <MenuButton label="Map" routeName="geomap" />}
                                </>
                            }
                            <MenuButton label="Data sources" internalLink="#data_sources" noborder={true} variant="text" />
                            <MenuButton label="Guide" externalLink="https://previr.com/analysis#previr-app" noborder={true} variant="text"/>
                        </div>
                        <IconButton onClick={handleMenuOpenMobile} >
                            <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <line x1="1" y1="1" x2="15" y2="1" stroke="white" strokeWidth="2" strokeLinecap="round" />
                                <line x1="1" y1="7.05505" x2="15" y2="7.05506" stroke="white" strokeWidth="2" strokeLinecap="round" />
                                <line x1="1" y1="13" x2="15" y2="13" stroke="white" strokeWidth="2" strokeLinecap="round" />
                            </svg>
                        </IconButton>
                    </div>
                }
            </AppBar>
        )
    );
};

Header.propTypes = {
    location: PropTypes.shape({ pathname: PropTypes.string }),
    classes: PropTypes.shape({ root: PropTypes.string, grow: PropTypes.string, logoButton: PropTypes.string }),
    modules: PropTypes.shape({
        strainTree: PropTypes.bool,
        predictions: PropTypes.bool,
        clades: PropTypes.bool,
        antigenic: PropTypes.bool,
    }),
    isAuthenticated: PropTypes.bool,
    signOut: PropTypes.func,
};
const mapStateToProps = ({ routes, parameters, metadata, user, render }) => ({
    ...routes,
    modules: metadata.modules,
    isAuthenticated: user.isAuthenticated,
    adminRights: user.permissions.adminRights,
    name: user.name,
    permissionTabs: user.permissions.tabs,
    hiddenMenu: render.hiddenMenu,
    hiddenMenuMobile: render.hiddenMenuMobile,
    isMobile: getIsMobile()
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ signOut, setHiddenMenu, setHiddenMenuMobile }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Header));
